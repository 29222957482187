<template>
  <a-modal
    :visible.sync="show"
    title="选择赠品"
    destroyOnClose
    width="45%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="type" label="产品属性">
              <a-select v-model="searchForm.type" placeholder="产品属性" style="width:100%;">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{ goodsType[item] }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item prop="goods_number"
                               label="商品编码">
              <a-input allowClear
                       v-model="searchForm.goods_number"
                       placeholder="商品编码"></a-input>
            </a-form-model-item>
          </a-col>-->
          <a-col :span="10">
            <a-form-model-item prop="goods_name" label="商品名称">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="goodsTable"
      :columnsData="columns"
      style="margin-left:-10px;"
      rowKey="sku_id"
      :row-selection="rowSelection"
      :tableData="tableData"
      :customRow="Rowclick"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :customHeight="300"
      :pageSize.sync="searchForm.page_count"
    >
      <div slot="footer">
        已选
        <span class="text-red-500">{{ rows.length }}</span>项
      </div>
    </base-table>
  </a-modal>
</template>

<script>
import { formatGoodsType, goodsType } from "@/utils/type"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    selectKeys: {
      type: Array,
      default: () => [],
    },

    selectRows: {
      type: Array,
      default: () => [],
    },

    getDataList: {
      type: Function,
    }
  },
  data() {
    return {
      goodsType: goodsType,
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      searchForm: {
        goods_number: "",
        goods_name: "",
        type: "",
        offers_time_start: "",
        offers_time_end: "",
        page: 1,
        page_count: 10,
        goods_create_time: [],
      },
      columns: [
        {
          title: "SKU",
          dataIndex: "sku_id",
          align: "center",
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
      ],
      tableData: [],
      total: 0,
      rows: this.selectRows,
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.sku_id)
            this.rows.push(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.sku_id
            )
            this.rows = this.rows.filter((el) => el.sku_id != record.sku_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.sku_id
            )
            this.rows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.rows = []
          }
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.selectKeys.includes(record.sku_id)
            }
          };
        }
      },
    }
  },
  mounted() {
    this.initData()
    this.rowSelection.selectedRowKeys = this.selectKeys
    this.rows = this.selectRows
  },
  methods: {
    async initData() {
      const { data, code } = await this.getDataList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 保存
    handleOk() {
      this.$emit("update:show", false)
      this.$emit("ok", this.rows)
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm() {
      this.searchForm.goods_number = ""
      this.searchForm.goods_name = ""
      this.searchForm.type = ""
      this.searchForm.offers_time_start = ""
      this.searchForm.offers_time_end = ""
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.searchForm.goods_create_time = []
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    Rowclick(record) {
      return {
        on: {
          click: () => {
            //如果是已选就去掉
            if (this.rowSelection.selectedRowKeys.includes(record.sku_id)) {
              this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.sku_id
              )
              this.rows = this.rows.filter(
                (el) => el.sku_id != record.sku_id
              )
            } else {
              this.rowSelection.selectedRowKeys.unshift(record.sku_id)
              this.rows.push(record)
            }
          },
        },
      }
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>