<template>
  <div>
    <a-card :title="$route.query.id ? '编辑满额活动' : '新增满额活动'">
      <a-form-model :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item class="mb-2" label="官方名称" prop="name" :rules="requiredRule">
          <a-input v-model="formData.name" placeholder="请输入官方名称" style="width:400px" :maxLength="30" />
        </a-form-model-item>
        <a-form-model-item class="mb-2" label="内部用名" prop="name_interior" :rules="requiredRule">
          <a-input v-model="formData.name_interior" placeholder="请输入内部名称" style="width:400px" :maxLength="30" />
        </a-form-model-item>
        <a-form-model-item class="mb-2" label="活动描述" prop="describe">
          <div class="flex">
            <a-checkbox v-model="hasDescribe">开启</a-checkbox>
            <div class="ml-2 act-tip">（开启后、商品页面会展示活动描述信息）</div>
          </div>
          <a-textarea v-if="hasDescribe" placeholder="请输入活动描述（顾客可见）" 
              v-model="formData.describe" :maxLength="100"
              :auto-size="{ minRows: 2, maxRows: 8 }"></a-textarea>
        </a-form-model-item>

        <a-form-model-item class="mb-2" label="适用平台">
          <a-checkbox style="width:100px" :disabled="isEdit"
              @change="onChangePlat('xcx')" :checked="formData.platform_xcx == 2">小程序</a-checkbox>
          <a-checkbox style="width:100px" :disabled="isEdit"
              @change="onChangePlat('pc')" :checked="formData.platform_pc == 2">网站</a-checkbox>
        </a-form-model-item>
        <a-form-model-item class="mb-2" label="活动时间" prop="desc_time">
          <a-range-picker
            style="width:400px"
            @change="onChangeRangeDate"
            v-decorator="['rangetime', { initialValue: [formData.start_time, formData.end_time] }]"
            v-model="formData.desc_time"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>

        <a-form-model-item class="mb-2" label="优惠内容" prop="name">
          <div class="favorable-content" v-for="(item,i) in formData.offers_ladder_data" :key="i">
            <div class="favorable-info-class">
              <div style="flex:1">
                <div style="width:50px; text-align:left; display:inline-block;">满</div>
                <a-input-number v-model="item.satisfy_price" :disabled="isEdit" />
                <span style="margin:0 50px 0 10px">元</span>

                <div style="width:30px; text-align:left; display:inline-block;">减</div>
                <a-input-number v-model="item.subtract_price" :disabled="isEdit" />
                <span style="margin-left:10px; margin-right:50px">元</span>

                <a-checkbox
                  v-if="item.free_shipping"
                  :checked="item.free_shipping===2"
                  :disabled="isEdit"
                  @change="handlerIsFree"
                >包邮</a-checkbox>
              </div>
              <div>
                <div class="close-class" @click="handlerDelPreferentialContent(item, i)">
                  <a-icon type="close" />
                </div>
              </div>
            </div>

            <div class="juzhong-class">
              <span style="width:50px;">赠品</span>
              <a-select
                v-model="item.gift_goods_sku_ids"
                mode="tags"
                style="width:80%"
                placeholder="请选择赠品"
                :open="false"
                disabled
                @deselect="(val) => { handlerGiftChange(val, i) }"
              >
                <a-select-option
                  v-for="el in giftList[i]"
                  :key="el.sku_id"
                  :value="el.sku_id ? el.sku_id.toString() : ''"
                >{{ el.goods_name }}{{ el.flavor_name ? `(${el.flavor_name})` : '' }}{{ el.specification_name ? `-(${el.specification_name})` : '' }}</a-select-option>
              </a-select>
              <a-button type="link" @click="handlerAddGift(i)">编辑</a-button>
            </div>
            <div></div>
            <div class="juzhong-class">
              <span style="width:50px;">赠券</span>
              <a-select
                v-model="item.gift_coupon_ids"
                mode="tags"
                disabled
                :open="false"
                style="width:80%"
                placeholder="请选择优惠券"
                @deselect="handlerCouponChange"
              >
                <a-select-option
                  v-for="el in couponList[i]"
                  :key="el.coupon_id"
                  :value="el.coupon_id.toString()"
                >{{ el.coupon_name }}</a-select-option>
              </a-select>
              <a-button type="link" @click="handlerAddCoupon(i)">编辑</a-button>
            </div>
          </div>
          <a-button
            style="margin-left:-10px"
            type="link"
            icon="plus"
            @click="handlerAddFavorable"
          >添加下一级别优惠</a-button>
        </a-form-model-item>

        <a-form-model-item class="mb-2" label="参加限制" prop="participation_restricted">
          <a-checkbox :disabled="isEdit" v-model="isLimitOnce">每人只能参与一次</a-checkbox>
        </a-form-model-item>
        
        <a-form-model-item label="适用范围" prop="apply_goods_type" :rules="requiredRule">
          <div>
            <a-radio-group v-model="formData.apply_goods_type" button-style="solid">
              <a-radio-button :disabled="isEdit" :value="1">全部商品</a-radio-button>
              <a-radio-button :disabled="isEdit" :value="2">自定义商品</a-radio-button>
              <a-radio-button :disabled="isEdit" :value="3">指定商品除外</a-radio-button>
            </a-radio-group>
            <base-table v-if="formData.apply_goods_type != 1"
                style="width:600px;" ref="tableRef"
                rowKey="goods_id"
                :columnsData="columns"
                :tableData="goodsList"
                :total="total"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count"
                :customHeight="1000">
              <template #toolbar>
                <a-button type="link" @click="handlerAddGoods">+添加商品</a-button>
              </template>
              <template #operation="{ record }">
                <a-button type="link" @click="handlerDelete(record)">删除</a-button>
              </template>
            </base-table>
          </div>
        </a-form-model-item>
        
        <a-form-model-item :wrapper-col="{ span: 14, offset: 3 }">
          <a-button style="margin:0 auto;" type="primary" @click="handlerSubmit">提交</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <LimitAddGoodsModal
      v-if="isShowAddGoods"
      :show.sync="isShowAddGoods"
      :selectKeys="selectKeys"
      :getDataList="getGoodsList"
      :selectRows="goodsList"
      @ok="handlerOkGoods"
    />
    <add-coupon-modal
      v-if="isShowAddCoupon"
      :show.sync="isShowAddCoupon"
      :selectKeys="selectCouponKeys"
      :selectRows="couponList[activeFavorable]"
      @ok="handlerOkCoupon"
    />
    <add-gift-modal
      v-if="isShowAddGift"
      :show.sync="isShowAddGift"
      :selectKeys="selectGiftKeys"
      :selectRows="giftList[activeFavorable]"
      :getDataList="getGiftGoodsList"
      @ok="handlerOkGift"
    />
  </div>
</template>

<script>
import LimitAddGoodsModal from "@/components/select-modal/add-goods.vue"
import { requiredRule } from "@/hooks/use-form-rules"
import { goodsColumn } from "./columns"

import {
  add,
  edit,
  getDetailById,
  getGiftGoodsList,
  getGoodsList,
} from "@/api/activity/full-reduce.js"
import AddCouponModal from "@/components/select-modal/add-coupon.vue"
import AddGiftModal from "@/components/select-modal/add-sku.vue"
import _ from "lodash"

export default {
  components: {
    LimitAddGoodsModal,
    AddCouponModal,
    AddGiftModal,
  },
  data() {
    return {
      requiredRule: requiredRule,
      labelCol: { span: 3 },
      wrapperCol: { span: 14 },
      columns: goodsColumn,
      isShowAddGoods: false,
      isShowAddGift: false,
      isShowAddCoupon: false,
      total: 0,
      activeKey: 0,
      deleteList: [],
      goodsList: [],
      selectKeys: [],
      selectCouponKeys: [],
      selectGiftKeys: [],
      couponList: [[]],
      giftList: [],
      activeFavorable: 0,
      searchForm: {
        name: "",
        goos_name: "",
        page: 1,
        page_count: 20,
      },
      formData: {
        name: "",
        name_interior: "",
        describe: "",
        platform_xcx: 1,
        platform_pc: 1,
        start_time: "",
        end_time: "",
        desc_time: [],
        offers_ladder_data: [
          {
            offers_ladder_id: 0,
            satisfy_price: 0,
            subtract_price: 0,
            gift_goods_sku_ids: [],
            gift_coupon_ids: [],
            free_shipping: 1,
          },
        ],
        apply_goods_type: 1,
        apply_goods_data: [],
        participation_restricted: 0,
      },
      hasDescribe: false,
      isLimitOnce: false,

      isEdit: false,
      isCopy: false,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.initData()
    }
    if (this.$route.query.type == "edit") {
      this.isEdit = true
    }
    if (this.$route.query.type == "copy") {
      this.isCopy = true
    }
  },

  methods: {
    getGiftGoodsList,
    getGoodsList,
    initData() {
      getDetailById({ id: this.$route.query.id }).then((res) => {
        if (res.code === 0) {
          this.hasDescribe = !!res.data.describe
          this.isLimitOnce = res.data.participation_restricted==1
          this.formData = res.data
          this.formData.desc_time = [
            this.formData.start_time,
            this.formData.end_time,
          ]
          this.goodsList = this.formData.apply_goods_data
          this.formData.offers_ladder_data.map((el, i) => {
            this.couponList[i] = el.gift_coupon_data
            this.giftList[i] = el.gift_goods_sku_data
            el.gift_goods_sku_ids = el.gift_goods_sku_data.map((o) =>
              o.sku_id.toString()
            )
            el.gift_coupon_ids = el.gift_coupon_data.map((o) =>
              o.coupon_id.toString()
            )
            return el
          })
        }
      })
    },
    onChangePlat(type){
      if(type=="xcx"){
        this.formData.platform_xcx = (this.formData.platform_xcx==2?1:2)
      }else if(type=="pc"){
        this.formData.platform_pc = (this.formData.platform_pc==2?1:2)
      }
    },
    handlerDelete(row) {
      this.deleteList.push(row)
      this.goodsList = this.goodsList.filter(
        (el) => el.goods_id != row.goods_id
      )
    },
    handlerSubmit() {
      if(this.formData.platform_xcx!=2 && this.formData.platform_pc!=2 ){
        this.$message.warning("请选择平台")
        return
      }
      let hasSelectCoupon = false
      let offers_ladder_data = this.formData.offers_ladder_data.map((item) => {
        item.gift_goods_sku_ids =
          typeof item.gift_goods_sku_ids === "string"
            ? item.gift_goods_sku_ids
            : item.gift_goods_sku_ids.join(",")
        item.gift_coupon_ids =
          typeof item.gift_coupon_ids === "string"
            ? item.gift_coupon_ids
            : item.gift_coupon_ids.join(",")
        delete item.gift_goods_sku_data
        delete item.gift_coupon_data

        if(item.gift_coupon_ids) hasSelectCoupon = true
        return item
      })
      if(this.formData.platform_pc==2 && hasSelectCoupon){
        this.$message.warning("网站活动不能赠券")
        return
      }
      let apply_goods_data = this.goodsList.map((el) => {
        return {
          goods_id: el.goods_id,
        }
      })

      this.formData.participation_restricted = this.isLimitOnce?1:0

      const params = _.cloneDeep(this.formData)
      params.start_time = params.desc_time[0].substr(0, 11) + "00:00:00"
      params.end_time = params.desc_time[1].substr(0, 11) + "23:59:59"
      params.offers_ladder_data = JSON.stringify(offers_ladder_data)
      params.apply_goods_data   = JSON.stringify(apply_goods_data)

      if (this.isEdit) {
        params.id = this.$route.query.id
        edit(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功!")
            this.$router.go(-1)
          }
        })
      } else if (this.isCopy) {
        add(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("复制成功!")
            this.$router.go(-1)
          }
        })
      } else {
        add(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("添加成功!")
            this.$router.go(-1)
          } else {
            // this.$message.error(res.message)
          }
        })
      }
    },
    handlerAddGoods() {
      this.selectKeys = this.goodsList.map((el) => el.goods_id)
      if (this.isShowAddGoods) {
        this.isShowAddGoods = false
      } else {
        this.isShowAddGoods = true
      }
    },

    handlerOkGoods(rows) {
      this.goodsList = rows
    },

    // 优惠券操作事件
    handlerOkCoupon(rows) {
      this.couponList[this.activeFavorable] = rows
      this.formData.offers_ladder_data[
        this.activeFavorable
      ].gift_coupon_ids = rows.map((el) => {
        return el.coupon_id.toString()
      })
    },
    handlerAddCoupon(i) {
      this.activeFavorable = i

      this.selectCouponKeys = []
      if (this.couponList[this.activeFavorable]) {
        this.selectCouponKeys = this.couponList[this.activeFavorable].map(
          (el) => el.coupon_id
        )
      } else {
        this.couponList[this.activeFavorable] = []
      }
      this.isShowAddCoupon = true
    },
    handlerCouponChange(val) {
      this.formData.offers_ladder_data[
        this.activeFavorable
      ].gift_coupon_ids = this.formData.offers_ladder_data[
        this.activeFavorable
      ].gift_coupon_ids.filter((el) => el != val)
      this.couponList[this.activeFavorable] = this.couponList.filter(
        (el) => el.coupon_id != val
      )
    },

    // 赠品操作事件
    handlerAddGift(i) {
      this.activeFavorable = i

      if (
        this.giftList[this.activeFavorable] &&
        this.giftList[this.activeFavorable].length > 0
      ) {
        this.selectGiftKeys = this.giftList[this.activeFavorable].map(
          (el) => el.sku_id
        )
      }
      this.isShowAddGift = true
    },
    handlerOkGift(rows) {
      this.giftList[this.activeFavorable] = rows
      this.formData.offers_ladder_data[
        this.activeFavorable
      ].gift_goods_sku_ids = rows.map((el) => {
        return el.sku_id.toString()
      })
    },
    handlerGiftChange(e, i) {
      this.formData.offers_ladder_data[
        i
      ].gift_goods_sku_ids = this.formData.offers_ladder_data[
        i
      ].gift_goods_sku_ids.filter((el) => el != e)
      this.giftList[i] = this.giftList[i].filter((el) => el.sku_id != e)
      this.$forceUpdate()
    },

    handlerIsFree(e) {
      this.formData.offers_ladder_data = this.formData.offers_ladder_data.map(
        (el) => {
          el.free_shipping = e.target.checked ? 2 : 1
          return el
        }
      )
    },

    handlerAddFavorable() {
      this.formData.offers_ladder_data.push({
        offers_ladder_id: 0,
        satisfy_price: 0,
        subtract_price: 0,
        gift_goods_sku_ids: [],
        gift_coupon_ids: [],
        free_shipping: this.formData.offers_ladder_data.length>0?this.formData.offers_ladder_data[0].free_shipping: 1,
      })
    },

    onChangeRangeDate(e) {
      this.formData.start_time = e[0]
      this.formData.end_time = e[1]
      this.formData.desc_time = [
        this.formData.start_time,
        this.formData.end_time,
      ]
    },
    handlerDelPreferentialContent(e, i) {
      this.formData.offers_ladder_data.splice(i, 1)
    },
  },
}
</script>

<style lang="less" scoped>
.goods-class {
  margin-left: 170px;
  width: 80%;
  background-color: #f3f3f3;
}
.favorable-content {
  border: 1px solid #eee;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #fafafa;
  padding: 10px 20px 10px;
  margin-bottom: 10px;
  position: relative;
  // margin: 0 20px 20px 0;
}

.favorable-info-class {
  display: flex;
  flex-direction: row;
}

// .close-class {
//   position: absolute;
//   top: 10;
//   right: 20;
//   background-color: red;
// }

.juzhong-class {
  display: flex;
  align-items: center;
}
.act-tip{
  color: #999;
}
</style>